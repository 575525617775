<template>
	<b-modal
		id="edit-asset-pool-distribution"
		title="Edit Asset Pool Distribution"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Company" label-class="font-weight-bold pt-0">
							{{ form.company }}
						</b-form-group>
					</b-col>

					<b-col lg="12" md="12" sm="12">
						<b-form-group
							label="Distribution Area"
							label-class="font-weight-bold pt-0"
						>
							{{
								form.connectedCompany + ' - ' + form.connectedStorageLocation
							}}
						</b-form-group>
					</b-col>

					<b-col lg="12" md="12" sm="12">
						<b-form-group
							label="Asset Type"
							label-class="font-weight-bold pt-0"
						>
							{{ form.assetType }}
						</b-form-group>
					</b-col>

					<b-col lg="12" md="12" sm="12">
						<b-form-group
							label="Total Assets"
							label-for="Total Assets"
							label-class="font-weight-bold pt-0"
							description="This is the estimated asset count on your selected distribution area"
						>
							<b-form-input
								id="total"
								name="Total Assets"
								type="number"
								v-model="form.total"
								min="0"
								v-validate="{ required: true, regex: /^([0-9]{1,})$/ }"
								placeholder="0"
							/>
							<span v-show="errors.has('Total Assets')" class="help-block">
								{{ errors.first('Total Assets') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col lg="12" md="12" sm="12">
						<b-form-group
							label="Notes"
							label-for="notes"
							label-class="font-weight-bold pt-0"
						>
							<b-form-textarea
								name="Some Description Here"
								type="text"
								v-model="form.notes"
								maxlength="200"
								:rows="3"
								placeholder="Notes"
							/>
							<span v-show="errors.has('Notes')" class="help-block">
								{{ errors.first('Notes') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'edit-asset-pool-distribution',
	components: {
		Loading,
	},
	data() {
		return {
			form: {
				company: '',
				companyId: '',
				connectedCompany: '',
				connectedCompanyId: '',
				connectedStorageLocation: '',
				connectedStorageLocationId: '',
				assetType: '',
				assetTypeId: '',
				total: 0,
				notes: '',
			},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (this.form.assetsOnLocationCount > this.form.total) {
				this.$toaster.warning(
					'Total inventory count must not be less than the accounted assets on the ' +
						this.form.connectedStorageLocation
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);
			this.handleSubmit();
		},
		getParam() {
			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assetPoolDistribution: this.form,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } =
					await assetPoolDistributionApi.saveAssetPoolDistribution(
						this.getParam()
					);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit(
						'onCloseEditAssetPoolDistribution',
						data.assetPoolDistribution
					);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error updating Asset Pool Distribution "${this.form.company}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating Asset Pool Distribution "${this.form.company}". Please try again.`
				);
			}
		},
		onReset() {
			/* Reset our form values */
			let currAPD = this.$store.getters.currAssetPoolDistribution;
			this.form = {
				id: currAPD.id,
				company: currAPD.company,
				companyId: currAPD.companyId,
				connectedCompany: currAPD.connectedCompany,
				connectedCompanyId: currAPD.connectedCompanyId,
				connectedStorageLocation: currAPD.connectedStorageLocation,
				connectedStorageLocationId: currAPD.connectedStorageLocationId,
				assetType: currAPD.assetType,
				assetTypeId: currAPD.assetTypeId,
				assetTypeCategory: currAPD.assetTypeCategory,
				assetsOnLocationCount: currAPD.assetsOnLocationCount,
				total: currAPD.total,
				notes: '',
				dateCreated: currAPD.dateCreated,
				createdBy: currAPD.createdBy,
				dateUpdated: currAPD.dateUpdated,
				updatedBy: currAPD.updatedBy,
			};

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style>
</style>