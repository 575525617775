import axios from 'axios';
import _ from 'lodash';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getAssetPoolDistributions(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getAssetPoolDistributions`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveAssetPoolDistribution(param) {
        let url = `${this.baseUrl}/saveAssetPoolDistribution`;
        return axios.post(url, {
            currUserId: param.currUserId,
            currTimeStamp: param.currTimeStamp,
            assetPoolDistribution: JSON.stringify(param.assetPoolDistribution)
        });
    }
}