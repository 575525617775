<template>
	<div class="animated fadeIn">
		<b-card title="Asset Pool Distribution"
			sub-title="Handles the asset pool distribution based on company connections within the supply chain">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toogle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.companyItems" :reduce="(company) => company.value"
												v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.assetTypeItems"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a asset type
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Connected Company">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.companyItems" :reduce="(company) => company.value"
												v-model="filterBy.connectedCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Connected Storage Location">
											<v-select class="style-chooser" label="text"
												:options="filterByOptions.storageLocationItems" :reduce="(loc) => loc.value"
												v-model="filterBy.connectedStorageLocation">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														>Start typing to search for a storage location
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-asset-pool-distribution v-show="!isViewer">
								Add Asset Pool Distribution
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Asset Pool Distributions in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Asset Pool Distributions to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(distributionArea)="row">
						{{
							row.item.connectedCompany +
							' - ' +
							row.item.connectedStorageLocation
						}}
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button size="sm" v-b-modal.edit-asset-pool-distribution
								v-b-tooltip.hover.top="'Edit Details'" variant="dark"
								@click.stop="updateSelAssetPoolDistribution(row.item)" class="mr-1" v-show="!isViewer">
								<i class="fa fa-pencil"></i>
							</b-button>
						</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<AssetPoolDistributionDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<AddAssetPoolDistribution :companyOptions="filterByOptions.companyItems" :allAssetTypesObj="allAssetTypesObj"
			:allConnectionsObj="allConnectionsObj" :allStorageLocationsObj="allStorageLocationsObj"
			:allAssetPoolDistributionsObj="allAssetPoolDistributionsObj" />
		<EditAssetPoolDistribution />
	</div>
</template>

<script>
// Components
import AddAssetPoolDistribution from '@/views/asset/assetPoolDistribution/AddAssetPoolDistribution';
import EditAssetPoolDistribution from '@/views/asset/assetPoolDistribution/EditAssetPoolDistribution';
import AssetPoolDistributionDetailsView from '@/views/asset/assetPoolDistribution/AssetPoolDistributionDetailsView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'asset-pool-distribution',
	components: {
		AddAssetPoolDistribution,
		EditAssetPoolDistribution,
		AssetPoolDistributionDetailsView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					label: 'Company',
					sortable: true,
				},
				{
					key: 'distributionArea',
					label: 'Distribution Area',
				},
				{
					key: 'assetType',
					sortable: false,
				},
				{
					key: 'total',
					label: 'Asset Pool Distribution',
					sortable: false,
				},
				'actions',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				company: config.companyDefaultValue,
				connectedCompany: config.companyDefaultValue,
				connectedStorageLocation: config.storageLocationDefaultValue,
				assetType: config.assetTypeDefaultValue,
			},
			filterBy: {
				company: config.companyDefaultValue,
				connectedCompany: config.companyDefaultValue,
				connectedStorageLocation: config.storageLocationDefaultValue,
				assetType: config.assetTypeDefaultValue,
			},
			prevFilter: {},
			filterByOptions: {
				companyItems: [],
				storageLocationItems: [],
				assetTypeItems: [],
			},

			allCompaniesObj: {},
			allConnectionsObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			allAssetPoolDistributionsObj: {},
			selAssetPoolDistribution: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		currLoggedUser() {
			return this.$store.state.auth.email;
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Company: 'company',
				'Distribution Area': 'Distribution Area',
				'Asset Type': 'assetType',
				Total: 'total',
				Notes: 'notes',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'AssetPoolDistribution-' +
				DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
			);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = this.$store.getters.companies;
				this.allStorageLocationsObj = this.$store.getters.storageLocations;
				this.allConnectionsObj = this.$store.getters.connections;
				this.allAssetTypesObj = this.$store.getters.assetTypes;

				this.filterByOptions.companyItems =
					DropDownItemsUtil.retrieveAllCompanyItems(this.allCompaniesObj);

				if (!this.isSuperAdmin) {
					this.filterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
				}

				this.filterByOptions.storageLocationItems =
					DropDownItemsUtil.retrieveStorageLocationItems(
						this.allStorageLocationsObj
					);

				this.filterByOptions.assetTypeItems =
					DropDownItemsUtil.retrieveAssetTypeItems(this.allAssetTypesObj);

				await this.retrieveData();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on(
			'onCloseAddAssetPoolDistribution',
			(assetPoolDistributionObj) => {
				if (!_.isEmpty(assetPoolDistributionObj)) {
					this.updateTable(assetPoolDistributionObj);
				}
			}
		);
		EventBus.$on(
			'onCloseEditAssetPoolDistribution',
			(assetPoolDistributionObj) => {
				if (!_.isEmpty(assetPoolDistributionObj)) {
					this.updateTable(assetPoolDistributionObj);
				}
			}
		);
	},
	methods: {
		updateTable(assetPoolDistributionObj) {
			if (_.isEmpty(assetPoolDistributionObj)) {
				return;
			}
			this.allAssetPoolDistributionsObj[assetPoolDistributionObj.id] =
				assetPoolDistributionObj;

			let filteredObj = Object.assign({}, this.allAssetPoolDistributionsObj);
			_.forEach(filteredObj, (distribution, id) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== distribution.companyId
				) {
					delete filteredObj[id];
				}

				if (
					this.filterBy.connectedCompany.id !== null &&
					this.filterBy.connectedCompany.id !== distribution.connectedCompanyId
				) {
					delete filteredObj[id];
				}

				if (
					this.filterBy.connectedStorageLocation.id !== null &&
					this.filterBy.connectedStorageLocation.id !==
					distribution.connectedStorageLocationId
				) {
					delete filteredObj[id];
				}

				if (
					this.filterBy.assetType.id !== null &&
					this.filterBy.assetType.id !== distribution.assetTypeId
				) {
					delete filteredObj[id];
				}
			});

			this.processAssetPoolDistribution(filteredObj);
		},
		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);

				if (this.isSuperAdmin) {
					filter.companyId = this.loggedUserCompany.id;
					const { data } =
						await assetPoolDistributionApi.getAssetPoolDistributions(
							filter,
							config.view.ADMIN,
							this.loggedUser.id
						);
					this.allAssetPoolDistributionsObj = data.assetPoolDistributions;
				} else {
					filter.companyId = this.loggedUserCompany.id;
					const { data } =
						await assetPoolDistributionApi.getAssetPoolDistributions(
							filter,
							config.view.COMPANY,
							this.loggedUser.id
						);
					this.allAssetPoolDistributionsObj = data.assetPoolDistributions;
				}

				this.processAssetPoolDistribution(this.allAssetPoolDistributionsObj);
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		processAssetPoolDistribution(assetPoolDistributions) {
			this.allAssetPoolDistributionsObj = assetPoolDistributions;

			this.items = Object.values(this.allAssetPoolDistributionsObj);
			this.items = _.sortBy(this.items, [
				'company',
				'connectedCompany',
				'connectedStorageLocation',
			]);
			this.totalRows = this.items.length;

			this.items.forEach((item) => {
				let distributionArea =
					item.connectedCompany + ' - ' + item.connectedStorageLocation;
				item['Distribution Area'] = distributionArea;
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});

			// update cache
			this.$store.dispatch(
				'setAllAssetPoolDistributions',
				this.allAssetPoolDistributionsObj
			);
		},

		updateSelAssetPoolDistribution(item) {
			this.selAssetPoolDistribution = item;
			this.$store.commit('SET_CURR_INVENTORY_COUNT', item);
			EventBus.$emit('onUpdateSelAssetPoolDistribution', item);
		},

		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddAssetPoolDistribution');
		EventBus.$off('onCloseEditAssetPoolDistribution');
	},
};
</script>

<style scoped></style>
