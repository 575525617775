<template>
	<b-modal
		id="add-asset-pool-distribution"
		size="lg"
		title="Add Asset Pool Distribution"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2" v-if="isSuperAdmin">
					<b-col sm="12 mb-2">
						<b>COMPANY</b>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="" label-for="Company">
							<v-select
								name="Company"
								class="style-chooser"
								label="text"
								:options="companyOptions"
								:reduce="(company) => company.value"
								v-model="selCompany"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">
								{{ errors.first('Company') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="my-2">
					<b-col sm="12 mb-2">
						<b>DISTRIBUTION AREA</b>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Company" label-for="Company">
							<v-select
								name="Connected Company"
								class="style-chooser"
								label="text"
								:options="connectedCompanyOptions"
								:reduce="(company) => company.value"
								v-model="selConnectedCompany"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Connected Company')" class="help-block">
								{{ errors.first('Connected Company') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group
							label="Storage Location"
							label-for="Connected Storage Location"
						>
							<v-select
								name="Connected Storage Location"
								class="style-chooser"
								label="text"
								:options="connectedStorageLocationOptions"
								:reduce="(loc) => loc.value"
								v-model="selConnectedStorageLocation"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a location
									</em>
								</template>
							</v-select>
							<span
								v-show="errors.has('Connected Storage Location')"
								class="help-block"
							>
								{{ errors.first('Connected Storage Location') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="my-2">
					<b-col sm="12 mb-2">
						<b>ASSETS</b>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Asset Type" label-for="Asset Type">
							<b-form-select
								id="assetType"
								name="Asset Type"
								v-model="selAssetType"
								v-validate="'selectRequired'"
								:options="filteredAssetTypeOptions"
							/>
							<span v-show="errors.has('Asset Type')" class="help-block">{{
								errors.first('Asset Type')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="my-2" v-show="selAssetType.id !== null">
					<b-col sm="6">
						<b-form-group
							label="Total Assets"
							label-for="Total Assets"
							description="This is the estimated asset count on your selected distribution area"
						>
							<b-form-input
								id="total"
								name="Total Assets"
								type="number"
								v-model="form.total"
								min="0"
								v-validate="{
									required: true,
									regex: /^([0-9]{1,})$/,
								}"
								placeholder="0"
							/>
							<span v-show="errors.has('Total Assets')" class="help-block">
								{{ errors.first('Total Assets') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Notes" label-for="notes">
							<b-form-textarea
								name="Some Description Here"
								type="text"
								v-model="form.notes"
								maxlength="200"
								:rows="3"
								placeholder="Notes"
							/>
							<span v-show="errors.has('Notes')" class="help-block">
								{{ errors.first('Notes') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-asset-pool-distribution',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allConnectionsObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		allAssetPoolDistributionsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				company: '',
				companyId: '',
				connectedCompany: '',
				connectedCompanyId: '',
				connectedStorageLocation: '',
				connectedStorageLocationId: '',
				assetType: '',
				assetTypeId: '',
				assetTypeCategory: '',
				total: 0,
				notes: '',
			},

			filteredAssetTypeOptions: [],

			selCompany: config.companyDefaultValue,
			selConnectedCompany: config.companyDefaultValue,
			selConnectedStorageLocation: config.storageLocationDefaultValue,
			selAssetType: config.assetTypeDefaultValue,

			// Options
			connectedCompanyOptions: [],
			connectedStorageLocationOptions: [],

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	watch: {
		selCompany: function (newVal, _oldVal) {
			if (newVal && newVal.id) {
				this.onChangeCompany();
			}
		},
		selConnectedCompany: function (newVal, _oldVal) {
			if (newVal && newVal.id) {
				this.onChangeConnectedCompany();
			}
		},
	},
	mounted() {
		if (this.isSuperAdmin) {
			this.selCompany = DropDownItemsUtil.getCompanyItem(
				this.loggedUserCompany
			);
			this.onChangeCompany();
		}

		this.resetDropDownOptions();
	},
	methods: {
		onChangeCompany() {
			if (this.selCompany !== config.companyDefaultValue) {
				this.connectedCompanyOptions =
					DropDownItemsUtil.retrieveConnectedCompanyItems(
						this.allConnectionsObj,
						this.selCompany
					);
				this.selConnectedCompany = config.companyDefaultValue;
			} else {
				this.resetDropDownOptions();
			}

			// filter entries in asset types
			this.filterAssetTypeOptions(this.selCompany);
		},
		filterAssetTypeOptions(selCompany) {
			if (selCompany === config.companyDefaultValue) {
				this.filteredAssetTypeOptions =
					DropDownItemsUtil.retrieveAssetTypeItems(this.allAssetTypesObj);
			} else {
				let selComAssetTypesObj = {};

				let assetTypeIds = Object.keys(this.allAssetTypesObj);
				assetTypeIds.forEach((id) => {
					let assetType = this.allAssetTypesObj[id];
					if (assetType.originId === this.selCompany.id) {
						selComAssetTypesObj[id] = assetType;
					}
				});

				this.filteredAssetTypeOptions =
					DropDownItemsUtil.retrieveAssetTypeItems(selComAssetTypesObj);
			}
		},

		onChangeConnectedCompany() {
			if (this.selConnectedCompany !== config.companyDefaultValue) {
				this.connectedStorageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selCompany.id,
						this.selConnectedCompany.id
					);
				this.selConnectedStorageLocation = config.storageLocationDefaultValue;
			} else {
				this.resetConnectedStorageLocationOptions();
			}
		},
		resetDropDownOptions() {
			this.resetConnectedCompanyOptions();
			this.resetConnectedStorageLocationOptions();
		},
		resetConnectedCompanyOptions() {
			this.connectedCompanyOptions = [];
			this.connectedCompanyOptions.push({
				value: config.companyDefaultValue,
				text: ' - Please select - ',
			});
			this.selConnectedCompany = config.companyDefaultValue;
		},
		resetConnectedStorageLocationOptions() {
			this.connectedStorageLocationOptions = [];
			this.connectedStorageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});
			this.selConnectedStorageLocation = config.storageLocationDefaultValue;
		},
		hasExistingAssetPoolDistribution() {
			let distributions = Object.values(this.allAssetPoolDistributionsObj);
			for (const distribution of distributions) {
				if (
					distribution.companyId === this.selCompany.id &&
					distribution.connectedCompanyId === this.selConnectedCompany.id &&
					distribution.connectedStorageLocationId ===
						this.selConnectedStorageLocation.id &&
					distribution.assetTypeId === this.selAssetType.id
				) {
					return true;
				}
			}

			return false;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (this.hasExistingAssetPoolDistribution()) {
				this.$toaster.warning(
					'Asset Pool Distribution for this connection and asset type already exist. Please check the table entries.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);
			this.handleSubmit();
		},
		updateFormValues(form) {
			return {
				company: this.selCompany.name,
				companyId: this.selCompany.id,
				connectedCompany: this.selConnectedCompany.name,
				connectedCompanyId: this.selConnectedCompany.id,
				connectedStorageLocation: this.selConnectedStorageLocation.name,
				connectedStorageLocationId: this.selConnectedStorageLocation.id,
				assetType: this.selAssetType.name,
				assetTypeId: this.selAssetType.id,
				assetTypeCategory: this.allAssetTypesObj[this.selAssetType.id].type,
				total: form.total,
				notes: form.notes,
			};
		},
		getParam() {
			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assetPoolDistribution: this.updateFormValues(this.form),
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetPoolDistributionApi.saveAssetPoolDistribution(
					this.getParam()
				);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit(
						'onCloseAddAssetPoolDistribution',
						data.assetPoolDistribution
					);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error creating Asset Pool Distribution "${this.form.company}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error creating Asset Pool Distribution "${this.form.company}". Please try again.`
				);
			}
		},
		onReset() {
			/* Reset our form values */
			this.form = {
				company: '',
				companyId: '',
				connectedCompany: '',
				connectedCompanyId: '',
				connectedStorageLocation: '',
				connectedStorageLocationId: '',
				assetType: '',
				assetTypeId: '',
				assetTypeCategory: '',
				total: 0,
				notes: '',
				dateCreated: null,
				createdBy: '',
				dateUpdated: null,
				updatedBy: '',
			};

			this.selCompany = DropDownItemsUtil.getCompanyItem(
				this.loggedUserCompany
			);
			this.onChangeCompany();

			this.selConnectedCompany = config.companyDefaultValue;
			this.selConnectedStorageLocation = config.storageLocationDefaultValue;
			this.selAssetType = config.assetTypeDefaultValue;

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style>
</style>